import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-card-list"
  }, [_c("el-collapse", {
    staticClass: "app-container",
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("el-collapse-item", {
      key: index,
      attrs: {
        name: item.ID
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", {
      staticClass: "report-category"
    }, [_vm._v("\n          " + _vm._s(item.reportNameEn) + "\n        ")])]), _vm._v(" "), _c("div", {
      staticClass: "report-list-wrapper"
    }, _vm._l(item.children, function (card) {
      return _c("div", {
        key: card.ID,
        staticClass: "report-item"
      }, [_c("el-card", {
        staticClass: "box-card"
      }, [_c("div", {
        staticClass: "clearfix",
        attrs: {
          slot: "header"
        },
        slot: "header"
      }, [_c("div", {
        staticClass: "report-card-header"
      }, [_c("span", {
        staticClass: "report-title"
      }, [_c("el-link", {
        on: {
          click: function click($event) {
            return _vm.OpenTableau(card);
          }
        }
      }, [_vm._v(_vm._s(card.reportNameZh))])], 1), _vm._v(" "), _c("span", {
        staticClass: "report-faverite"
      }, [card.isFavorite === 0 ? _c("i", {
        staticClass: "el-icon-star-off",
        on: {
          click: function click($event) {
            return _vm.favorite(card);
          }
        }
      }) : _vm._e(), _vm._v(" "), card.isFavorite === 1 ? _c("i", {
        staticClass: "el-icon-star-on",
        on: {
          click: function click($event) {
            return _vm.favorite(card);
          }
        }
      }) : _vm._e()])])]), _vm._v(" "), _c("div", {
        staticClass: "report-card-body"
      }, [_c("div", {
        staticClass: "report-card-left"
      }, [card.reportReportTool <= 0 ? _c("img", {
        staticClass: "link-icon",
        staticStyle: {
          width: "2.6vw",
          height: "2.6vw"
        },
        attrs: {
          src: require("@/assets/G2-Tableau.png"),
          fit: "fill"
        }
      }) : _vm._e(), _vm._v(" "), card.reportReportTool == 2 ? _c("img", {
        staticStyle: {
          width: "2.6vw",
          height: "2.6vw"
        },
        attrs: {
          alt: "",
          src: require("@/assets/G2-Tableau.png"),
          fit: "fill"
        }
      }) : _vm._e(), _vm._v(" "), card.reportReportTool == 1 ? _c("img", {
        staticStyle: {
          width: "2.6vw",
          height: "2.6vw"
        },
        attrs: {
          alt: "",
          src: require("@/assets/G2-SSRS.png"),
          fit: "fill"
        }
      }) : _vm._e()]), _vm._v(" "), _c("div", {
        staticClass: "report-card-right"
      }, [_c("div", {
        staticClass: "item"
      }, [_vm._v(_vm._s(card.menuDesc))])])]), _vm._v(" "), _c("div", {
        staticClass: "report-card-footer"
      }, [_c("div", {
        staticClass: "content"
      }, [_vm._v("\n                " + _vm._s(card.rmtool.reportToolName) + "\n              ")])])])], 1);
    }), 0)], 2);
  }), 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.favoriteDialog,
      title: _vm.$t("report_card.add_collected")
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.favoriteDialog = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.favoriteInfo
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("report_card.save_as"),
      "label-width": "120px",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.favoriteInfo.name,
      callback: function callback($$v) {
        _vm.$set(_vm.favoriteInfo, "name", $$v);
      },
      expression: "favoriteInfo.name"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterAddFavoritDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeAddFavoritDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };